<template>
  <div class="stat-wrap">
    <div class="stat-header">
      <van-popup v-model="showPop" position="top">
        <van-radio-group v-model="liveCodeId">
          <van-radio
            v-for="item in liveCodeList"
            :key="item.liveCodeId"
            :name="item.liveCodeId"
            >{{ item.liveCodeName }}</van-radio
          >
        </van-radio-group>
        <div class="save-btn" @click="handleConfirm">
          <span>确认</span>
        </div>
      </van-popup>
      <div class="stat-header-name">
        <span class="stat-header-label">活码名称：</span><span
            v-if="isAll"
            class="van-ellipsis stat-header-value"
            @click="showPopup"
          >{{ liveCodeName }}<van-icon name="play"/></span
        ><span v-else class="van-ellipsis">{{ liveCodeName }}</span>
      </div>
      <div class="stat-header-time">
        <span
          class="stat-header-timefl"
          >统计时间：{{ endTimestamp ? transfromTime(endTimestamp) : "-" }}</span
        >
        <!-- <span>
          <span class="stat-header-timefl">订阅提醒</span>
          <van-switch v-model="checked" size="0.64rem" />
        </span> -->
      </div>
      <van-grid clickable :column-num="3" :border="false">
        <van-grid-item
          v-for="item in Object.values(statisticData)"
          :key="item.title"
          :text="item.title"
        >
          <span slot="icon" class="van-ellipsis">{{ item.value }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <van-cell title="客户变化表" class="stat-tabs">
      <van-tabs slot="label" v-model="tabVal" @change="handleTabsChange">
        <van-tab
          v-for="item in tabList"
          :key="item.key"
          :title="item.title"
          :name="item.key"
        >
          <div class="stat-divider"></div>
          <v-table
            :ref="item.key"
            :table-columns="item.tableColumns"
            :api="item.api"
            :params="{ liveCodeId,endTimestamp:yesterdayTimestamp,tenantId }"
          />
        </van-tab>
      </van-tabs>
    </van-cell>
  </div>
</template>

<script>
import {
  statisticOverviewSingle,
  statisticListDate,
  statisticListFellow
} from '@/api/modules/liveCode'
import VTable from '@/components/VTable'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'ImageAndText',
  components: { VTable },
  data() {
    return {
      statisticListDate,
      statisticListFellow,
      isAll: false,
      showPop: false,
      tenantId: '',
      // checked: true,
      liveCodeId: '',
      liveCodeName: '',
      endTimestamp: '',
      yesterdayTimestamp: '',
      tabVal: 'statisticListDate',
      liveCodeList: [
      ],
      tabList: [
        {
          key: 'statisticListDate',
          title: '按日期查看',
          api: statisticListDate,
          tableColumns: [
            {
              title: '日期',
              prop: 'date',
              type: 'date',
              width: '34%'
            },
            {
              title: '客户总数',
              prop: 'totalConsumer',
              width: '21%'
            },
            {
              title: '新增客户',
              prop: 'newConsumer',
              width: '21%'
            },
            {
              title: '流失客户',
              prop: 'lostConsumer',
              width: '21%'
            }
          ]
        },
        {
          key: 'statisticListFellow',
          title: '按员工查看',
          api: statisticListFellow,
          tableColumns: [
            {
              title: '员工',
              prop: 'fellow',
              width: '34%',
              icon: 'fellowAvator'
            },
            {
              title: '客户总数',
              prop: 'totalConsumer',
              width: '21%'
            },
            {
              title: '新增客户',
              prop: 'newConsumer',
              width: '21%'
            },
            {
              title: '流失客户',
              prop: 'lostConsumer',
              width: '21%'
            }
          ]
        }
      ],
      statisticData: {
        totalNewConsumer: {
          title: '总添加客户数',
          value: 0
        },
        newConsumer: {
          title: '昨日添加客户数',
          value: 0
        },
        lostConsumer: {
          title: '昨日流失客户数',
          value: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  created() {
    // const tenantId = this.userInfo.tenant
    this.tenantId = global.APP_CONFIG.tenantId
    const { id, name, time } = this.$route.query
    const [newId, newName, newTime] = [id, name, time].map(k => decodeURIComponent(k))
    if (newId) {
      const idArr = newId.split(',')
      const nameArr = newName.split(',')
      this.liveCodeList = idArr.map((liveCodeId, i) => ({
        liveCodeId,
        liveCodeName: nameArr[i]
      }))
      this.liveCodeId = idArr[0]
      this.liveCodeName = nameArr[0]
      this.isAll = idArr.length > 1
      this.endTimestamp = Number(newTime)
      this.yesterdayTimestamp = moment(Number(newTime)).subtract(1, 'days').valueOf()
      this.getStatCount()
      this.$nextTick(() => {
        this.$refs[this.tabVal][0].reset()
      })
    }
    else {
      this.$router.push('/404')
    }
  },
  methods: {
    showPopup() {
      this.showPop = true
    },
    handleConfirm() {
      this.showPop = false
      this.liveCodeList.some(l => {
        if (l.liveCodeId === this.liveCodeId) {
          this.liveCodeName = l.liveCodeName
          return true
        }
      })
      this.$refs[this.tabVal][0].reset()
      this.getStatCount()
    },
    handleTabsChange() {
      this.$nextTick(() => {
        this.$refs[this.tabVal][0].reset()
      })
    },
    async getStatCount() {
      const { liveCodeId, endTimestamp, tenantId } = this
      const { code, data } = await statisticOverviewSingle({ liveCodeId, endTimestamp, tenantId })
      if (code === 0) {
        data &&
          Object.entries(this.statisticData).forEach(([key]) => {
            this.statisticData[key].value = data[key] || 0
          })
      }
    },
    transfromTime(time) {
      return moment(time).format('M月D日')
    }
  }
}
</script>

<style lang="less" scoped>
@primary-color: #3173cd;
.stat-wrap {
  height: 100vh;
  overflow: auto;
  .stat-divider {
    position: relative;
    width: 100vw;
    background-color: #f5f7fa;
    height: 0.266rem;
  }
}
.stat-header {
  padding: 0.533rem;
  .stat-header-name {
    display: flex;
    font-size: 0.426rem;
    line-height: 0.667rem;
    color: #333;
    margin-bottom: 0.4rem;
    font-weight: 500;
    .van-icon-play {
      transform: rotate(90deg);
      font-size: 0.3rem;
      position: absolute;
      right: 0;
      top: .12rem;
      // vertical-align: text-top;
      // margin-left: 0.266rem;
      // margin-top: 0.06rem;
    }
  }
  .stat-header-label{
    flex-shrink: 0;
  }
  .stat-header-value{
    padding-right: 0.40rem;
    position: relative;
  }
  .stat-header-time {
    font-size: 0.32rem;
    color: #666666;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.4rem;
    // line-height: 0.48rem;
    .stat-header-timefl {
      float: left;
      line-height: 0.693rem;
      margin-right: 0.267rem;
    }
  }
  .van-grid {
    border-radius: 0.106rem;
    /deep/ .van-grid-item__text {
      margin-top: 0.21333rem;
    }
  }
  // /deep/ [class*="van-hairline"]::after {
  //   border: none;
  // }
}
.van-switch--on {
  background-color: @primary-color;
}
.stat-tabs {
  padding-left: 0;
  > .van-cell__title > span {
    padding-left: 0.42667rem;
  }
  .van-cell {
    color: #333;
  }
  /deep/ .van-tabs__wrap {
    padding-left: 0.42667rem;
    .van-tabs__line {
      background-color: @primary-color;
    }
    .van-tab {
      padding: 0;
      justify-content: flex-start;
      flex: inherit;
      margin-right: 0.53rem;
      color: #98999b;
      font-size: 0.32rem;
      font-weight: 500;
      &.van-tab--active {
        font-size: 0.373rem;
        color: #333;
      }
    }
  }
  /deep/ .van-tabs__content {
    .van-list .van-cell {
      &::after {
        left: 0;
        right: 0;
      }
    }
  }
}
.van-radio-group {
  max-height: 70vh;
  overflow: auto;
  margin-bottom: 0.53rem;
  /deep/ .van-radio {
    font-size: 0.373rem;
    margin: 0.53rem 0.53rem 0 0.53rem;
    .van-radio__icon {
      display: none;
    }
    .van-radio__icon--checked + .van-radio__label {
      color: @primary-color;
    }
    .van-radio__label {
      color: #333;
      font-weight: 500;
    }
  }
}
.save-btn {
  color: @primary-color;
  font-weight: 500;
  font-size: 0.427rem;
  line-height: 1.2rem;
  border-top: 0.02667rem solid #ebedf0;
  text-align: center;
}
</style>
