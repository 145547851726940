import service from '@/api'
const pre = '/liveCodeApi'
/**
* 单个活码总人数统计
*/
export const statisticOverviewSingle = (params) => {
  return service.post(`${pre}/v1/statistic/overview/single`, params)
}
/**
* 单个活码按日期分析列表
*/
export const statisticListDate = (params) => {
  return service.post(`${pre}/v1/statistic/list/date`, params)
}
/**
* 单个活码按顾问分析列表
*/
export const statisticListFellow = (params) => {
  return service.post(`${pre}/v1/statistic/list/fellow`, params)
}
