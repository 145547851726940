<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-grid
      :column-num="tableColumns.length"
      :border="false"
      :center="false"
      class="v-table-header"
    >
      <van-grid-item
        v-for="t in tableColumns"
        :key="`th_${t.prop}`"
        :text="t.title"
        :style="t.width ? { flexBasis: t.width } : {}"
      />
    </van-grid>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
      <van-grid
        v-for="(item, i) in list"
        :key="`tr_${i}`"
        :column-num="4"
        :border="false"
        :center="false"
      >
        <template v-for="(t, j) in tableColumns">
          <van-grid-item
            :key="`td_${i}_${j}`"
            class="van-ellipsis"
            :icon="item[t.icon]"
            :text="formatText(item, t)"
            :style="t.width ? { flexBasis: t.width } : {}"
          />
        </template>
      </van-grid>
    </van-list>
  </van-pull-refresh>
</template>
<script>
export default {
  name: 'VTable',
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tableColumns: {
      type: Array,
      default: () => [],
      required: true
    },
    api: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      pageIndex: 1
    }
  },
  methods: {
    formatText(item, t) {
      let text = item[t.prop]
      if (text || text === 0) {
        if (t.type === 'date') {
          text = text.substr(5)
        }
        else if (typeof text === 'number') {
          text = text.toString()
        }
      }
      else {
        text = '-'
      }
      return text
    },
    reset() {
      this.pageIndex = 1
      this.list = []
      this.onLoad()
    },
    async onLoad() {
      if (this.api) {
        if (this.refreshing) {
          this.list = []
          this.refreshing = false
        }
        const { pageSize, pageIndex } = this
        const {
          code,
          data: { dataList, page }
        } = await this.api({ ...this.params, pageSize, pageIndex })
        this.loading = false
        if (code === 0) {
          this.pageIndex++
          this.list = this.list.concat(dataList)
          if (this.list.length >= page.totalCount) {
            this.finished = true
            this.pageIndex = 1
          }
        }
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    }
  }
}
</script>
<style scoped lang="less">
.v-table-header {
  /deep/ .van-grid-item__text {
    font-weight: 500;
  }
}
.van-grid {
  border-bottom: 0.02667rem solid #ebedf0;
  /deep/ .van-grid-item {
    height: 1.2rem;
    &:first-child {
      padding-left: 0.32rem;
    }
  }
  /deep/ .van-grid-item__content {
    flex-direction: row;
    padding: 0 0.21333rem;
    align-items: center;
    .van-grid-item__text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #333;
      font-size: 0.373rem;
    }
    // .van-grid-item__icon {
    //   font-size: 0.8rem;
    // }
    .van-grid-item__icon + .van-grid-item__text {
      margin-top: 0;
      margin-left: 0.16rem;
    }
    .van-icon__image {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}
</style>
